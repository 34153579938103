/**
 * @name: 统计分析-挂账额统计接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 统计分析-挂账额统计接口文件
 * @update: 2023-08-04 17:46
 */
import {postJ} from "@/request"
import type { IHang, IHangParam } from "./types";

/**
 * 挂账额统计
 * @param data
 * @returns
 */
export const statisticsPendingAccountsApi = (data: IHangParam) => postJ<IHang[]>("/admin/statistics/pendingAccounts", data)
/**
 * 挂账额导出
 * @param data
 * @returns
 */
export const statisticsPendingAccountsExportApi = (data: IHangParam) => postJ("/admin/statistics/pendingAccounts/export", data, "blob")
