
/**
 * @name: 统计分析-挂账额统计
 * @author: itmobai
 * @date: 2023-08-04 17:53
 * @description： 统计分析-挂账额统计
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { statisticsPendingAccountsApi, statisticsPendingAccountsExportApi } from "@/apis/statistics/hang"
import { gateGetGateListApi } from "@/apis/gate/info"
import Big from "big.js"
import type { IHang, IHangParam } from "@/apis/statistics/hang/types"
import type { IGate } from "@/apis/gate/info/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile, getPreAndNextTime } from "@/utils/common";

@Component({})
export default class statisticsHang extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IHang[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IHangParam = {}
  // 表单参数
  modelForm: Partial<IHangParam> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "交易日期",
        prop: "time",
        type: "daterange",
        search: true,
        hide: true,
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"]
      },
      {
        label: "挂账客户",
        prop: "ids",
        search: true,
        hide: true,
        type: "select",
        searchMultiple: true,
        dicUrl: "/api/admin/hangAccountCustomer/queryHangAccountCustomerList",
        dicFormatter: (res: any) => ({list: res.data, label: "customerName", value: "id"})
      },
      {
        label: "挂账客户",
        prop: "customerName",
        align: "center"
      },
      {
        label: "挂账金额",
        prop: "amountOnCredit",
        align: "center"
      },
      {
        label: "已付金额",
        prop: "amountPaid",
        align: "center"
      },
      {
        label: "未付金额",
        prop: "unpaidAmount",
        align: "center"
      }
    ]
  }

  reset () {
    // 获取时间段
    const dateRange = getPreAndNextTime("06:00:00", "05:59:59")
    this.queryParam.time = dateRange
    this.getList()
  }

  exportExcel () {
    const query: IHangParam = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      query.startTime = this.queryParam.time[0]
      query.endTime = this.queryParam.time[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.time
    statisticsPendingAccountsExportApi(query).then(e => {
      exportFile(e, `挂账额${query.startTime}-${query.endTime}.xlsx`)
    })
  }

  getList () {
    this.tableLoading = true
    const query: IHangParam = deepCopy(this.queryParam)
    if (this.queryParam.time && this.queryParam.time.length) {
      query.startTime = this.queryParam.time[0]
      query.endTime = this.queryParam.time[1]
    } else {
      query.startTime = ''
      query.endTime = ''
    }
    delete query.time
    statisticsPendingAccountsApi(query).then(e => {
      if (e) {
        let amountOnCreditSum = new Big(0)
        let amountPaidSum = new Big(0)
        let unpaidAmountSum = new Big(0)
        for (let i = 0; i < e.length; i++) {
          let item = e[i]
          amountOnCreditSum = new Big(amountOnCreditSum || 0).plus(item.amountOnCredit || 0)
          amountPaidSum = new Big(amountPaidSum || 0).plus(item.amountPaid || 0)
          unpaidAmountSum = new Big(unpaidAmountSum || 0).plus(item.unpaidAmount || 0)
        }
        // @ts-ignore
        this.tableData = [...e, {
          customerName: '合计(元)',
          // @ts-ignore
          amountOnCredit: amountOnCreditSum.toNumber() || '0.00',
          // @ts-ignore
          amountPaid: amountPaidSum.toNumber() || '0.00',
          // @ts-ignore
          unpaidAmount: unpaidAmountSum.toNumber() || '0.00',
        }];
        this.tableLoading = false;
      }
    })
  }

  created () {
    this.reset()
  }
}
